import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import React from 'react';

export default function Copyright() {
  return (
    <Typography component="div" variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://ezcloudllc.com/">
        E-Z Cloud
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
      <br />
      <Link color="inherit" href="https://ezcloudllc.com/terms-of-service" target="_blank">
        Terms of Service
      </Link>{' '}
      and{' '}
      <Link color="inherit" href="https://responder.services.ezcloudllc.com/privacy-policy/" target="_blank">
        Privacy Policy
      </Link>
    </Typography>

  );
}
